export default {
  periods: {
    1: {
      title: "⏰ 3 Monate vor dem Umzug",
      interval: 86400 * 30 * 3
    },
    2: {
      title: "⏰ 4 Wochen vor dem Umzug",
      interval: 86400 * 30
    },
    3: {
      title: "⏰ 1 Woche vor dem Umzug",
      interval: 86400 * 7
    },
    4: {
      title: "⏰ 1 Tag vor dem Umzug",
      interval: 86400
    },
    5: {
      title: "⏰ Am Umzugstag",
      interval: 0
    },
    6: {
      title: "⏰ Nach dem Umzug",
      interval: -86400
    }
  },
  tasks: {
    1010: {
      period: 1,
      title: "Alte Wohnung kündigen",
      duration: "2 Stunden",
      hint:
        "Kündigungsschreiben findest z.B. bei <a rel='noopener noreferrer' href='https://www.immobilienscout24.de/umzug/ratgeber/vorlagen/musterbrief-kuendigung.html' target='_blank'>Immobilienscout</a>"
    },
    1011: {
      period: 1,
      title: "Nachmieter finden",
      hint:
        "Anzeige bei Facebook, Immobilienscout oder im Bekanntenkreis aufgeben"
    },
    1030: {
      period: 1,
      title: "Standortrelevante Verträge und Abos durchsehen/kündigen",
      hint:
        "Fitnessstudio, Zeitungsabonnements usw. (zur Hilfe: Briefkasten beobachten, E-Mail Postfach durchsuchen)"
    },
    1040: {
      perod: 1,
      title: "Sperrmüll beantragen",
      hint:
        "Das kannst du, meist online, bei den Abfallwirtschaftsbetrieben deiner Stadt"
    },
    1050: {
      period: 1,
      title: "Telefon und Internet ummelden oder kündigen",
      hint:
        "Sollte dein aktueller Anbieter an der neuen Adresse nicht liefern können, hast du ein Sonderkündigungsrecht"
    },
    2010: {
      period: 2,
      title: "Neue Wohnung ausmessen & Möbel planen",
      hint:
        "Nutze das kostenfreie Tool <a rel='noopener noreferrer' href='http://www.sweethome3d.com/de/' target='_blank'>SweetHome 3D</a> für ein 3D Modell deiner Wohnung"
    },
    2020: {
      period: 2,
      title: "Nachsendeauftrag beantragen",
      hint:
        "Service der <a target='_blank' rel='noopener noreferrer' href='https://shop.deutschepost.de/shop/nachsenden-lagern/nachsendeservice.jsp?tid=sk08_13'>Deutschen Post</a>"
    },
    2021: {
      period: 2,
      title: "Umzugshelfer suchen oder Umzugsfirma beauftragen"
    },
    2022: {
      period: 2,
      title: "Umzugskartons ausleihen oder kaufen",
      hint:
        "Anzahl kannst du mit unserem Kartonrechner (siehe unten) herausfinden"
    },
    2040: {
      period: 2,
      title: "Versorung von Strom, Gas etc. ummelden"
    },
    3001: {
      period: 3,
      title: "Halteverbot beantragen",
      hint:
        "Entweder macht das dein Umzugsunternehmen oder über die städtische Webseite"
    },
    3002: {
      period: 3,
      title: "Werkzeug für Abbau und Aufbau bereitstellen"
    },
    3003: {
      period: 3,
      title: "Große Möbel abbauen"
    },
    3004: {
      period: 3,
      title: "Umzugskarton packen und beschriften",
      hint:
        "Beschriftung mit dem Umzugskram Stickertool (siehe unten) durchführen"
    },
    3005: {
      period: 3,
      title: "Lebensmittel verbrauchen"
    },
    3006: {
      period: 3,
      title: "Neue Wohnung bezugsbereit?",
    },

    4001: {
      period: 4,
      title: "Verpflegung an beiden Standorten bereitstellen"
    },
    4002: {
      period: 4,
      title: "Kühlschrank abtauen und vom Strom nehmen"
    },
    4003: {
      period: 4,
      title: "Am neuen Standort Beleuchtung prüfen"
    },
    4004: {
      period: 4,
      title: "Übergabeprotokoll Vorlage ausdrucken",
      hint:
        "<a href='https://www.mieterbund.de/service/uebergabeprotokoll.html' target='_blank'>Vorlage auf Mieterbund.de</a>"
    },
    5001: {
      period: 5,
      title: "Namensschilder anbringen"
    },
    5002: {
      period: 5,
      title: "Übergabeprotokoll mit altem Vermieter durchgehen"
    },
    6001: {
      period: 6,
      title: "Kartons auspacken",
      hint:
        "Freunde fragen oder auf Kleinanzeigenportalen verschenken oder verkaufen"
    },
    6002: {
      period: 6,
      title: "Adressänderung durchführen",
      hint:
        "Ämter (Arbeitsamt, Einwohnermeldeamt, usw.), Online-Firmen, Freunde, Vereine, Versicherungen"
    }
  }
};
