import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

import tasks from "../data/tasks";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  legendLabel: {
    fontSize: 16,
    color: "#263238",
    fontWeight: 800,
    paddingTop: 20,
    marginBottom: 5,
    lineHeight: 1.2
  },
  checkboxLabel: {
    // marginTop: 20,
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  taskHint: {
    fontSize: 12,
    color: "rgb(120,120,120)",
    fontWeight: 400,
    display: "block",
    marginLeft: 32,
    marginTop: -2,
    marginBottom: 5,
    "& a": {
      color: "rgb(120,120,120)",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
      "&:visited": {
        color: "rgb(120,120,120)",
      }
    },
    
  }
});

const test = d => {
  if (d.slice(0, 3) === "vor") {
    return "bereits verspätet";
  }
  return d;
};

function Tasklist(props) {
  const { classes, period, toggleTask, completed, moveDay } = props;

  const tasksItems = tasks.tasks;

  let taskRender = Object.keys(tasksItems).map((item, i) => {
    if (tasksItems[item].period !== props.period) return null;
    let taskId = parseInt(item);
    let checked = completed.includes(taskId);
    return (
      <div key={i}>
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              checked={checked}
              onChange={() => toggleTask(taskId)}
              value={taskId}
            />
          }
          label={tasksItems[item].title}
          classes={{ label: classes.checkboxLabel }}
        />
        <div
          className={classes.taskHint}
          dangerouslySetInnerHTML={{ __html: tasksItems[item].hint }}
        />
      </div>
    );
  });

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel
        component="legend"
        focused={false}
        className={classes.legendLabel}
      >
        {moveDay ? (
          <div>
            <span>Erledigen </span>
            <Moment
              filter={test}
              subtract={{ seconds: tasks.periods[period].interval }}
              fromNow
            >
              {moveDay}
            </Moment>{" "}
            ({tasks.periods[period].title})
          </div>
        ) : (
          <div>{tasks.periods[period].title}</div>
        )}
      </FormLabel>
      <FormGroup>{taskRender}</FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(Tasklist);
