import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { withRouter, Link } from "react-router-dom";

import logoHeaderRetina from "../assets/img/logo-header@2x.png";
const styles = {
  root: {
    flexGrow: 1
  },
  toolbar: {
    textAlign: "center"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  active: {
    fontWeight: 800
  },
  logoLink: {
    lineHeight: 0,
    margin: "0 auto",
    display: "inline-block"
  },
  logo: {
    maxWidth: 300
  }
};

function Header(props) {
  const { classes } = props;

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // function handleMenuClick(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleClose() {
  //   setAnchorEl(null);
  // }

  // const toPage = page => {
  //   if (props.location.pathname !== page) setRedirect(page);
  // };

  // const [redirect, setRedirect] = useState(null);

  // React.useEffect(() => {
  //   console.log("redirect change...", redirect);
  // }, [redirect]);

  return (
    <AppBar position="static" color="primary">
      {/* {redirect && <Redirect to={redirect} />} */}
      <Toolbar className={classes.toolbar}>
        {/* <IconButton
          onClick={handleMenuClick}
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton> */}
        <Link to="/" className={classes.logoLink}>
  
          <img
            src={logoHeaderRetina}
            className={classes.logo}
            alt="Umzugskram Logo Header"
          />
        </Link>
        {/* <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => toPage("/")}
            className={props.location.pathname === "/" ? classes.active : null}
          >
            Umzugskram
          </MenuItem>
          <MenuItem
            onClick={() => toPage("/impressum")}
            className={
              props.location.pathname === "/impressum" ? classes.active : null
            }
          >
            Impressum
          </MenuItem>
          <MenuItem
            onClick={() => toPage("/datenschutz")}
            className={
              props.location.pathname === "/datenschutz" ? classes.active : null
            }
          >
            Datenschutzerklärung
          </MenuItem>
        </Menu> */}
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(withStyles(styles)(Header));
