import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/lab/Slider";
import Checkbox from "@material-ui/core/Checkbox";

import carton01 from "../assets/img/comic-carton-01.png";
import carton02 from "../assets/img/comic-carton-02.png";

import { useStateAndLocalStorage } from "persistence-hooks";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    //padding: 14
    //fontWeight: 800,
    //width: 50
  },
  marginTop: {
    marginTop: 30
  },
  slider: {
    padding: "25px 0px"
  },
  centered: {
    alignSelf: "center"
  },
  paddingY: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  cartonCount: {
    position: "relative",
    height: 150,
    width: 200,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 40,
    [theme.breakpoints.up("md")]: {
      height: 250,
    width: 300,
    }
  },
  cap: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    width: 200,
    [theme.breakpoints.up("md")]: {
      width: 300,
    }
  },
  count: {
    position: "absolute",
    top: -35,
    left: -5,
    fontSize: 80,
    fontWeight: 800,
    zIndex: 9,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      top: -75,
    left: -10,
    fontSize: 150,
    }
  },
  carton: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 200,
    [theme.breakpoints.up("md")]: {
      width: 300,
    }
  },
  boxTitle: {
    marginBottom: theme.spacing(3)
  }
}));

const CartonCalculator = () => {
  const classes = useStyles();

  const STORAGE_KEY = "CartonCalculator";

  function calculateCartons() {
    const least = 30,
      addWithAtticOrBasement = 10;

    let newCartons = least;
    if (values.oldHasPersons > 1) {
      newCartons += least - 5;
    }
    if (values.oldHasPersons > 2) {
      newCartons += least - 10;
    }
    if (values.oldHasPersons > 3) {
      newCartons += least - 15;
    }
    if (values.oldHasPersons > 4) {
      newCartons += least - 20;
    }
    if (values.oldHasPersons > 5) {
      newCartons += (values.oldHasPersons - 5) * 5;
    }

    if (values.oldHasSize < 100) {
    } else if (values.oldHasSize < 125) {
      newCartons += 10;
    } else if (values.oldHasSize < 150) {
      newCartons += 20;
    } else {
      newCartons += 30;
    }

    if (values.oldHasAttic) newCartons += addWithAtticOrBasement;
    if (values.oldHasBasement) newCartons += addWithAtticOrBasement;
    //console.log("CARTONS", newCartons, "values",values.cartons);
    if (newCartons !== values.cartons)
      setValues({ ...values, cartons: newCartons });
  }

  const handleInputChange = e => {
    const { id, value } = e.target;
    setValues({ ...values, [id]: parseInt(value) });
  };

  const handleSliderChange = name => (e, value) => {
    setValues({ ...values, [name]: parseInt(value) });
  };

  const handleCheckboxChange = e => {
    const { value, checked } = e.target;
    setValues({ ...values, [value]: checked });
  };

  const [values, setValues] = useStateAndLocalStorage(
    {
      oldHasAttic: false,
      oldHasBasement: false,
      oldHasSize: 20,
      oldHasPersons: 1,
      cartons: 0
    },
    STORAGE_KEY
  );
  // eslint-disable-next-line
  React.useEffect(() => {
    // eslint-disable-next-line
    calculateCartons();
    // eslint-disable-next-line
  }, [values]);

  return (
    <Grid container className={classes.paddingY}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h5" color="textPrimary">
              Größe der alten Wohnfläche
            </Typography>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              id="oldHasSize"
              className={classes.textField}
              onChange={handleInputChange}
              variant="outlined"
              label="qm"
              type="integer"
              value={values.oldHasSize || 20}
              InputProps={
                {
                  //startAdornment: <InputAdornment position="start">qm</InputAdornment>
                }
              }
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Slider
              classes={{ container: classes.slider }}
              value={values.oldHasSize}
              min={20}
              max={300}
              step={5}
              onChange={handleSliderChange("oldHasSize")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.marginTop}>
          <Grid item xs={12}>
            <Typography component="h5" color="textPrimary">
              Anzahl der Personen im Haushalt
            </Typography>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              id="oldHasPersons"
              className={classes.textField}
              onChange={handleInputChange}
              variant="outlined"
              label="Anzahl"
              type="integer"
              value={values.oldHasPersons || 1}
              InputProps={
                {
                  // startAdornment: (
                  //   //<InputAdornment position="start">Anzahl</InputAdornment>
                  // )
                }
              }
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Slider
              classes={{ container: classes.slider }}
              value={values.oldHasPersons}
              min={1}
              max={10}
              step={1}
              onChange={handleSliderChange("oldHasPersons")}
            />
          </Grid>
        </Grid>
        <FormGroup row className={classes.marginTop}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.oldHasBasement}
                onChange={handleCheckboxChange}
                value="oldHasBasement"
              />
            }
            label="Keller vorhanden"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.oldHasAttic}
                onChange={handleCheckboxChange}
                value="oldHasAttic"
              />
            }
            label="Dachboden vorhanden"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={6} className={classes.centered}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="textPrimary"
          className={classes.boxTitle}
        >
          Anzahl der Kartons
        </Typography>
        <div className={classes.cartonCount}>
          <img src={carton02} className={classes.cap} alt="Cap" />
          <div className={classes.count}>{values.cartons}</div>
          <img src={carton01} className={classes.carton} alt="Carton" />
        </div>
      </Grid>
    </Grid>
  );
};
export default CartonCalculator;
