import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import comicMoveHeader from "../assets/img/comic-move-header.png";

const styles = theme => ({

  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(3)}px 10px ${theme.spacing(0)}px`
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  teaser: {
    maxWidth: "100%"
  },
  
});

function Hero(props) {
  const { classes } = props;

  return (
    <div className={classes.heroUnit}>
      <div className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Umzugskram - Der digitale Umzugsbegleiter
        </Typography>
        {/* <img src={logoHero} className={classes.logoHero} alt="umzugskram Logo" /> */}
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Umzugskram ist der digitaler Begleiter und Helfer bei deinem Umzug. Es zeigt dir alle notwendigen Aufgaben rund um den Umzug mit nützlichen Hinweisen. Ebenso findest du auf Umzugskram kleine Tools wie das Berechnen der Kartonanzahl und Erstellen von Aufklebern für die Kartons.
        </Typography>

        <img src={comicMoveHeader} className={classes.teaser} alt="logo" />
      </div>
    </div>
  );
}

export default withStyles(styles)(Hero);
