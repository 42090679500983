import 'babel-polyfill'
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import App from "./App";
import Imprint from "./Imprint";
import Privacy from "./Privacy";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from 'history'

import ReactGA from "react-ga";

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      //light: '#33ab9f',
      main: "#EF7B30",
      //dark: '#00695f',
      contrastText: "#fff"
    },
    secondary: {
      //light: '#ff7961',
      main: "#EF7B30",
      //dark: '#ba000d',
      contrastText: "#000"
    }
  },
  overrides: {
    MuiStepIcon: {
      root: {
        width: 28,
        height: 28
      },
      text: {
        fontSize: 16
      }
    },
    MuiStepLabel: {
      root: {},
      alternativeLabel: {
        fontSize: 16
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 18,
        textAlign: "center"
      }
    },
    MuiSlider: {
      root: {
        overflow: "hidden",
        padding: "0 25px"
      }
    }
  }
});


ReactGA.initialize("UA-140501408-1");

const history = createBrowserHistory();

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const routing = (
  <Router history={history}>
    <MuiThemeProvider theme={theme}>
      <div>
        <Route exact path="/" component={App} />
        <Route path="/impressum" component={Imprint} />
        <Route path="/datenschutz" component={Privacy} />
      </div>
    </MuiThemeProvider>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
