import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

// material-ui
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

import Typography from "@material-ui/core/Typography";
//import { withStyles } from "@material-ui/core/styles";

// other packages
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";

//import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CookieConsent from "react-cookie-consent";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";

import Hero from "./components/Hero";
import Tasklist from "./components/Tasklist";
import CartonCalculator from "./components/CartonCalculator";
import CartonSticker from "./components/CartonSticker";
// assets
//import logo from "./logo.svg";
import comicMove02 from "./assets/img/comic-move-02.png";
import comicMove05 from "./assets/img/comic-move-05.png";
import comicMove07 from "./assets/img/comic-move-07.png";

import ReactGA from "react-ga";

import { useStateAndLocalStorage } from "persistence-hooks";

import "./App.css";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  icon: {
    marginRight: theme.spacing(2)
  },

  container: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(2 * 3))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  section: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`
  },
  boxTitle: {
    marginBottom: theme.spacing(3)
  },
  box: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  white: {
    backgroundColor: theme.palette.background.paper
  },
  margin: {
    margin: theme.spacing(1)
  },
  marginY: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  paddingY: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  moveDay: {
    //minWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    marginBottom: 0
  },
  test: {},
  centered: {
    alignSelf: "center"
  },
  teaser: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    [theme.breakpoints.up("md")]: {
      width: "60%",
      maxWidth: 800
    }
  }
}));

//class App extends React.Component {

const App = props => {
  const classes = useStyles();

  const STORAGE_KEY = "Umzugskram";

  const page = props.location.pathname + props.location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);

  const [app, setApp] = useStateAndLocalStorage(
    {
      moveDay: null,
      activeStep: 0,
      completed: [],
      taskCompleted: []
    },
    STORAGE_KEY
  );
  // eslint-disable-next-line
  React.useEffect(() => {
    // eslint-disable-next-line
    //console.log("app changed ... ", app);
    // eslint-disable-next-line
  }, [app]);

  const onChangeDate = date => {
    setApp({ ...app, moveDay: date });
  };

  const getSteps = () => {
    return ["Vor dem Umzug", "An den Umzugstagen", "Nach dem Umzug"];
  };

  const handleStep = step => () => {
    setApp({ ...app, activeStep: step });
  };
  // const handleComplete = () => {
  //   app.completed[app.activeStep] = true;
  //   this.handleNext();
  // };

  // const handleInputChange = e => {
  //   const { id, value } = e.target;
  //   setApp({ ...app, [id]: parseInt(value) });
  // };

  // const handleSliderChange = name => (e, value) => {
  //   setApp({ ...app, [name]: parseInt(value) });
  // };

  // const handleCheckboxChange = e => {
  //   const { value, checked } = e.target;
  //   setApp({ ...app, [value]: checked });
  // };

  const toggleTask = id => {
    const taskId = parseInt(id);
    let newCompleted = app.taskCompleted;
    if (!app.taskCompleted.includes(taskId)) {
      newCompleted.push(parseInt(taskId));
    } else {
      newCompleted = app.taskCompleted.filter(function(value, index, arr) {
        return value !== taskId;
      });
    }
    setApp({ ...app, taskCompleted: newCompleted });
    return true;
  };

  const steps = getSteps();

  return (
    <React.Fragment>
      <Helmet>
        <title>
        Umzugskram - Der digitale Umzugsbegleiter
        </title>
        <meta
          name="description"
          content="Umzugskram ist der digitaler Begleiter und Helfer bei deinem Umzug. Es bietet eine Todo-Liste aller Umzugsphasen und hilfreiche Tools."
        />
      </Helmet>
      <CssBaseline />
      <Header />
      <main>
        <Hero />

        <div className={classes.white}>
          <div className={classes.container}>
            <div className={classes.section}>
              <FormGroup>
                <Typography component="h3" variant="h5" align="center">
                  Wann soll der Umzug stattfinden?
                </Typography>
                <div className={classes.moveDay}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      autoOk={true}
                      value={app.moveDay}
                      onChange={onChangeDate}
                      disablePast={true}
                      format="dd, DD.MM.Y"
                      cancelLabel="Abbrechen"
                      placeholder="Umzugsdatum eingeben"
                      clearable={true}
                      clearLabel="Löschen"
                      classes={{
                        root: classes.pickerRoot
                        //: classes.pickerInput
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {app.moveDay && (
                    <Typography variant="h6" align="center">
                      <Moment fromNow>{app.moveDay}</Moment>
                    </Typography>
                  )}
                </div>
              </FormGroup>
            </div>
            {/* <div className={classes.section}>
                  <Typography variant="h5" align="center">
                    Deine offene Aufgaben
                  </Typography>
                  <Typography variant="h3" align="center">
                    {taskCompleted.length} von{" "}
                    {Object.keys(tasks.tasks).length}
                  </Typography>
                </div> */}
            <div className={classes.section}>
              <Stepper nonLinear alternativeLabel activeStep={app.activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      onClick={handleStep(index)}
                      completed={app.completed[index]}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
        </div>

        {app.activeStep === 0 && (
          <div className={classes.container}>
            <Paper className={classes.box} elevation={1}>
              <Grid container className={classes.paddingY}>
                <Grid item xs={12} md={6} className={classes.centered}>
                  <Typography
                    component="h2"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    className={classes.boxTitle}
                  >
                    Aufgaben vor dem Umzug
                  </Typography>
                  <img src={comicMove02} className={classes.teaser} alt="" />
                </Grid>
                <Grid item xs={12} md={6} className={classes.marginTop}>
                  {/* <Typography
                      component="h3"
                      variant="h4"
                      align="center"
                      color="textPrimary"
                      className={classNames(classes.marginY)}
                    >
                      Deine Aufgaben vor dem Umzug
                    </Typography> */}
                  <Tasklist
                    period={1}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                  <Tasklist
                    period={2}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                  <Tasklist
                    period={3}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={classes.box} elevation={1}>
              <CartonCalculator />
            </Paper>

            <Paper className={classes.box} elevation={1}>
              <CartonSticker />
            </Paper>
          </div>
        )}
        {app.activeStep === 1 && (
          <div className={classes.container}>
            <Paper className={classes.box} elevation={1}>
              <Grid container className={classes.paddingY}>
                <Grid item xs={12} md={6} className={classes.centered}>
                  <Typography
                    component="h2"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    className={classes.boxTitle}
                  >
                    Während des Umzuges
                  </Typography>
                  <img src={comicMove05} className={classes.teaser} alt="" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tasklist
                    period={4}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                  <Tasklist
                    period={5}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}

        {app.activeStep === 2 && (
          <div className={classes.container}>
            <Paper className={classes.box} elevation={1}>
              <Grid container className={classes.paddingY}>
                <Grid item xs={12} md={6} className={classes.centered}>
                  <Typography
                    component="h2"
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    className={classes.boxTitle}
                  >
                    Nach dem Umzug
                  </Typography>
                  <img src={comicMove07} className={classes.teaser} alt="" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tasklist
                    period={6}
                    completed={app.taskCompleted}
                    toggleTask={toggleTask}
                    moveDay={app.moveDay}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}
      </main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="privacy"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#FFFFFF",
          color: "#E18042",
          fontSize: "14px"
        }}
        expires={150}
      >
        Um unsere Webseite für Sie optimal zu gestalten und fortlaufend
        verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung
        der Webseite stimmen Sie der Verwendung von Cookies zu.{" "}
        <a style={{ color: "white" }} href="/datenschutz">
          Datenschutzerklärung
        </a>
      </CookieConsent>
    </React.Fragment>
  );
};

App.propTypes = {
  //classes: PropTypes.object.isRequired
};

export default App;
