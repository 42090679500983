import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Link
} from 'react-router-dom'
//import { Redirect, withRouter } from "react-router-dom";

const styles = {
  root: {
    flexGrow: 1
  },
  name: {
    display: "inline-block",
    marginRight: 10,
    fontSize: 14
  },
  button: {
    margin: "0 10px",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  footer: {
    backgroundColor: "white",
    padding: 30,
    textAlign: "center",
    color: "grey",
    fontSize: 14
  }
};

function Footer(props) {
  const { classes } = props;

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // function handleMenuClick(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  // function handleClose() {
  //   setAnchorEl(null);
  // }

  // const toPage = page => {
  //   if (props.location.pathname !== page) setRedirect(page);
  // };

  // const [redirect, setRedirect] = useState(null);

  // React.useEffect(() => {
  //   //console.log("redirect change...", redirect);
  // }, [redirect]);

  return (
    <footer className={classes.footer}>
      {/* {redirect && <Redirect to={redirect} />} */}
    
      <Link to="/" className={classes.button}>
      Campuskram © 2019
      </Link>
      {" | "}
      <Link to="/impressum" className={classes.button}>
        Impressum
      </Link>
      {" | "}
      <Link to="/datenschutz" className={classes.button}>
        Datenschutz
      </Link>
    </footer>
    // <AppBar position="static" color="primary">
    //   {redirect && <Redirect to={redirect} />}
    //   <Toolbar>
    //     <IconButton
    //       onClick={handleMenuClick}
    //       className={classes.menuButton}
    //       color="inherit"
    //       aria-label="Menu"
    //     >
    //       <MenuIcon />
    //     </IconButton>
    //     <a href="/" className={classes.logoLink}>
    //       <img src={logoHeaderRetina} className={classes.logo} alt="Umzugskram Logo Header" />
    //     </a>
    //     <Menu
    //       id="simple-menu"
    //       anchorEl={anchorEl}
    //       open={Boolean(anchorEl)}
    //       onClose={handleClose}
    //     >
    //       <MenuItem
    //         onClick={() => toPage("/")}
    //         className={props.location.pathname === "/" ? classes.active : null}
    //       >
    //         Umzugskram
    //       </MenuItem>
    //       <MenuItem
    //         onClick={() => toPage("/impressum")}
    //         className={
    //           props.location.pathname === "/impressum" ? classes.active : null
    //         }
    //       >
    //         Impressum
    //       </MenuItem>
    //       <MenuItem
    //         onClick={() => toPage("/datenschutz")}
    //         className={
    //           props.location.pathname === "/datenschutz" ? classes.active : null
    //         }
    //       >
    //         Datenschutzerklärung
    //       </MenuItem>
    //     </Menu>
    //   </Toolbar>
    // </AppBar>
  );
}

export default withStyles(styles)(Footer);
