import React from "react";

import { Helmet } from "react-helmet";

import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
const styles = theme => ({
  container: {
    width: "auto",

    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(2 * 3))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  box: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
});

function Imprint(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Umzugskram - Impressum</title>
        <meta
          name="description"
          content="Impressum von umzugskram.de - Inhaber Stefan Helker"
        />
      </Helmet>
      <CssBaseline />
      <Header />
      <main>
        <div className={props.classes.container}>
          <Paper className={props.classes.box} elevation={1}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <h2>Impressum</h2>
                <strong>Angaben gemäß § 5 TMG</strong>
                <p>
                  Stefan Helker
                  <br />
                  medienkram by Stefan Helker
                  <br />
                  Landgrafenstrasse 77
                  <br />
                  50931 Köln
                </p>
                <br />
                <strong>Kontakt</strong>
                <p>
                  Telefon: ‭+49 156 78567932‬
                  <br />
                  E-Mail: hallo@umzugskram.de
                </p>
                <br />
                <strong>Bild-Referenz</strong>
                <p>
                  <a
                    href="http://www.freepik.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Designed by brgfx / Freepik
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.freepik.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Designed by pch.vector / Freepik
                  </a>
                </p>
                <br />
                <strong>Streitschlichtung</strong>
                <p>
                  Die Europäische Kommission stellt eine Plattform zur
                  Online-Streitbeilegung (OS) bereit:{" "}
                  <a
                    href="https://Fec.europa.eu/consumers/odr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://ec.europa.eu/consumers/odr
                  </a>
                  .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
                </p>
                <p>
                  Wir sind nicht bereit oder verpflichtet, an
                  Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <br />
                <strong>Haftung für Inhalte</strong>{" "}
                <p>
                  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen.
                </p>{" "}
                <p>
                  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                  Informationen nach den allgemeinen Gesetzen bleiben hiervon
                  unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                  Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                  möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                </p>
                <br />
                <strong>Haftung für Links</strong>{" "}
                <p>
                  Unser Angebot enthält Links zu externen Websites Dritter, auf
                  deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                  für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                  die Inhalte der verlinkten Seiten ist stets der jeweilige
                  Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>{" "}
                <p>
                  Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                  ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                  nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                  werden wir derartige Links umgehend entfernen.
                </p>
                <br />
                <strong>Urheberrecht</strong>{" "}
                <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebrauch gestattet.
                </p>{" "}
                <p>
                  Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                  erstellt wurden, werden die Urheberrechte Dritter beachtet.
                  Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                  Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                  aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Inhalte umgehend entfernen.
                </p>
                <p>
                  Quelle:{" "}
                  <a
                    href="https://www.e-recht24.de/impressum-generator.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.e-recht24.de/impressum-generator.html
                  </a>
                </p>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default withStyles(styles)(Imprint);
